@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


/* Colors */
:root {


/*NEW THEAM Variables */

    
    /*Colors Variables */

    /* Button Colors bgGreen */
    --button-bg-color-green: #0a5c48;
    --button-hover-bg-color-transparent: transparent;
    --text-color-OffWhite: #fff0e1;
    --text-color-black-hover: black;

    /* Button Colors bgTransparent */
    --button-bg-color-transparent: transparent;
    --button-hover-bg-color-green: #0a5c48;
    --text-color-black: black;
    --text-color-OffWhite-hover: #fff0e1;

    /* Text Color */
    --button-bg-color-transparent: transparent;
    --text-color-black: black;
    --text-color-OffWhite: #fff0e1;
    --text-color-Green: #0a5c48;

    /* Button Background Yellow */
    --button-bg-color-Yellow: #fff0e1;
    --text-color-Green: #0a5c48;

    /* Heading Color Green */
    --text-color-Green: #0a5c48;

    /* Border Color Green */
    --border-color-Green: #0a5c48;

/* 

    "var(--text-color-Green)"
    "var(--text-color-black)"
    "var(--text-color-OffWhite)"
    "var(--text-color-Green)"


    "var(--button-bg-color-green)"
    "var(--button-bg-color-transparent)"


    "var(--border-color-Green)"

 */




    --poppins-font: 'Poppins', sans-serif;
    --open-sans-font: 'Open Sans', sans-serif;
    /* Custom CSS for buttons  */
    --button-bg-color: #ffffff;
    --button-hover-bg-color: #000;
    --button-hover-bg-color-trans: #fff0;

    /* Custom CSS for Assessment buttons  */
    /* --button-bg-color-green: #00a789; */
    /* --button-hover-bg-color-hover: #00a789; */


    /* Custom CSS for Background Color  */
    --bg-color: #44aa97;


    /* Custom CSS for Copyright Background  */
    --copyright-bg-color: #388c7d;


    /* Custom CSS for NavLink Color  */
    --navbar-color: #44aa97;


    /* Custom CSS for Tab Heading Color  */
    --tab-heading-color: #fec107;


    /* Custom CSS for Pricing Button Color  */
    --pricing-btn-color: #44aa97;


    /* Custom CSS for Product Cards Color  */
    --prduct-card-color: #ffe083;


    /* Custom CSS for Test Library heading Background Color  */
    --test-library-btn-bg-color: #fec107;


    /* Custom CSS for Select Test heading Background Color  */
    --select-test-btn-bg-color: #0a5c48;


    /* Custom CSS for Select Test Card Background Color  */
    --select-test-card-bg-color: #e0eff2;


    /* Custom CSS for Assessment back button Color  */
    --assessment-back-btn-color: #e0eff2;


    /* Custom CSS for Stepper Color  */
    --stepper-color: #ffc104;


    /* Custom CSS for Stepper Border Color  */
    --stepper-border-color: #ffc104;


    /* Custom CSS for Stepper Line Color  */
    --stepper-line-color: #ffefbe;


    /* Custom CSS for Stepper Active Line Color  */
    --stepper-active-line-color: #fec106;

    /* Custom CSS for Link Color */
    --link-color: #53b09d;

    /* Custom CSS for footer text Color */
    --footer-text-color: #ffffff;



    /* Fonts  */
    /* Inner Navbar Fonts  */
    --nav-fontsize: 19px;

    /* Button Fonts  */
    --btn-fontsize: 14px;

    --dark-font: #000;


    --button-bg-color-assessment: #0a5c48;
    --button-hover-bg-color-assessment: #0a5c48;


}

::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--border-color-Green);
    border-radius: 5px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb:hover {
    background: #388c7d;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    line-height: normal;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: normal;
    overflow-x: hidden;
}

p {
    letter-spacing: 0.2px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.customTabs .MuiPaper-root {
    width: 90%;
}

.error_msg_style {
    color: #ff0000;
}

#filled-basic[name="assessment_name"] {
    padding-top: 10px;
    padding-bottom: 10px;
}

.custom-mui-datatable table tbody{
  background-color: white;
}
.custom-mui-datatable table tbody tr:hover{
  background-color: #f0d6bb !important;
}
.custom-mui-datatable table thead tr th{
  background-color: #fafafa  !important;
}

.css-11mde6h-MuiPaper-root {
    box-shadow: none !important;
    background: unset !important;
    border: unset !important;
}
.css-11mde6h-MuiPaper-root tbody tr:hover {
    background: unset !important;
}
.css-11mde6h-MuiPaper-root thead tr th {
   background-color: #fafafa !important;
}

.ql-editor {
    height: 400px !important;
}
.custom-mui-datatable > div + div {
    max-height: 500px !important;
}

@media(max-width:960px) {
    p {
        font-size: 16px !important;
        line-height: normal !important; 
    }

    h1 {
        font-size: 28px !important;
    }

    h2 {
        font-size: 24px !important;
    }
}


@media(max-width:600px) {
    p {
        font-size: 15px !important;

    }
}


.PricingForm svg {
    fill: white;
}



.tabs_padding[role="tabpanel"]>div {
    padding: 0 !important;
}

.Mui-checked {
    color: var(--button-bg-color-green) !important;
}

.MuiTooltip-tooltip {
    color: #000 !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
    background-color: unset !important;
}

.css-1mfk1m1-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: var(--border-color-Green) !important;
}

.css-1mfk1m1-MuiInputBase-root-MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: var(--border-color-Green) !important;
}

/* label.Mui-focused + div fieldset{border: 2px solid #276678 !important;} */


/* label.Mui-focused + div fieldset{border: 2px solid #276678 !important;} */


/* Talent Assessment */
.talentRadioBtnChecked{
    margin-top: 10px;
    color: #fff!important;
}
.talentRadioBtnUnChecked{
    margin-top: 10px;
}

.talentRadioBtnUnChecked span, .talentRadioBtnChecked span{
    font-size: 13px !important;
}
.talentRadioBtnChecked label{
    width: 100%;
    padding: 24px 10px;
    margin: 0px; 
    border: 2px solid #ededed;
    border-radius: 8px !important;
    background-color: var(--button-bg-color-green) !important;
    transition:  0.5s ease-in-out;
}
.talentRadioBtnUnChecked label{
    width: 100%;
    padding: 24px 10px;
    margin: 0px;
    border: 2px solid #ededed;
    border-radius: 8px !important;
    transition:  0.5s ease-in-out;
}
.talentRadioBtnUnChecked label:hover{ 
    border: 2px solid var(--button-bg-color-green); 
    transition:  0.5s ease-in-out;
}
.talentRadioBtnUnChecked:hover svg{ 
    color: var(--button-bg-color-green); 
    transition:  0.5s ease-in-out;
}
.talentRadioBtnChecked > * > .Mui-checked{
    color: #fff !important;
}
.cameraSetupWarning{
    border-radius:4px;
    background-color: #f0d6bb;
    padding: 10px 16px;
}

.cameraSetupTroubleShoot{
    margin-top: 1.5rem;
    border-radius:8px;
    background-color: #c8d7de;
    padding: 10px 16px;
}

/*=======yadav===========*/
.error_class div label{
  border:1px dotted #d32f2f;
}
.error_class_font_color {
  color:#d32f2f
}
div.error_class_font_color {
    margin-left: 10px;
}
/* .MuiPaper-root.ReactMuiTable{
    border-radius: 8px !important;
} */



.MuiTableRow-root.css-1sdw5na-MuiTableRow-root:hover td {
    background-color:#f0d6bb !important;
.progressBarcommonCss:hover{
  background-color: #bff4bc;
}
}
.progressBarcommonCss{
  border: 1px solid #E0E0E0; 
  width: 15%;
  height: 20px;
  float:left;
  cursor:pointer;
}
.progressBarcommonCss.active{  
  background-color: var(--button-bg-color-green);
}
.progressBarcommonCssHeading{ 
  width: 15%;
  height: 20px;
  float:left;
  margin-left:2px; 
}
.progressBarcommonCss.fstepDiv{
  border-radius: 6px 0 0 6px;
}
.progressBarcommonCss.lstepDiv{
  border-radius: 0 6px 6px 0;

}
/* .VideoInput {
    margin-left: 50px;
    margin-top: 20px;
    max-width: 350px;
} */
table.condidate_table tr > * {
    white-space: nowrap;
}
table.condidate_table tbody tr{
    background: white;
}
table.condidate_table tbody tr:hover  {
    background: #f0d6bb;
}
table.condidate_table tr > th:first-child,
table.condidate_table tr > td:first-child{
    position: sticky !important;
    left: 0;
    background: #fff;
   
    text-align:left;
    width:180px;
    border-right: 1px solid #dcdcdc;
}
table.condidate_table tr > th:first-child{
    background:rgb(251 251 251);
}
table.condidate_table tr > td:first-child{
    background:white;
}
table.condidate_table tr > th:last-child,
table.condidate_table tr > td:last-child{
    position: sticky !important;
    right: 0;
    background: #fff;
    background:white;
    text-align:center;
    max-width: 75px !important;
    border-left: 1px solid #dcdcdc;
}
table.condidate_table tr > th:last-child{
    background:rgb(251 251 251);
}
table.condidate_table tr > th:last-child button,
table.condidate_table tr > td:last-child button{
    min-width:auto;
}

div#long-menuSetting > .MuiPaper-root {
    right: 2% !important;
    left: unset !important;
    position: absolute;
    top: 50% !important;
    max-height: 400px !important;
}
div#long-menuSetting ul li + div {
    position: sticky;
    bottom: 0;
    left: 0px;
}

table.condidate_table tr td > .MuiInputBase-root {
    font-size: 12px;
    border-radius: 8px;
}
table.condidate_table tr td > .MuiInputBase-root > div {
padding: 12px 30px 12px 12px !important;
}
.fullscreen.fullscreen-enabled {
    background-color: #fff !important;
}
.custom-code-editor section .monaco-editor{
    padding-top:13px; 
}
.css-8jf96i-MuiSlider-valueLabel:before{
    width:2px !important;
    height:22px !important;
    left:2% !important;
}
.RadioButtonLable{
    font-size:12px !important;
}
.ProfileTable div div table thead tr th{
background-color:white !important;
}
.ProfileTable div div table tbody tr:hover{
background-color:#f0d6bb !important;
cursor:pointer;
}
.ProfileTable div div table tr th:last-child,
.ProfileTable div div table tr td:last-child {
    text-align: end;
}
.ProfileTable div div table tr td:last-child div {
    justify-content: end;
}
.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

.data-not-fonud table tr .datatables-noprint{
    margin-top: 25%;
}
.data-not-fonud table tr td.datatables-noprint div div{
    font-weight: 500;
    font-size: 24px;
}
.tss-1x5mjc5-MUIDataTable-root .datatables-noprint {
    border: none;
}
.data-not-fonud table tr :hover {
    background-color: #fff;
}

/* @media (min-width: 0px) and (max-width: 240px) {
    .LinerProgressbgc span {
      background-color: green !important;
    }
  } */



/* .LinerProgressbgc span{
    background-color:#cb7b7a !important;
} */
.FadeModal.MuiBox-root{
    visibility:visible !important;
    opacity:1 !important;
}
.MyCandidateTable tbody  tr{
    background-color:white !important;
}
.MyCandidateTable tbody  tr:hover {
    background-color:#f0d6bb !important;
}
.MyCandidateTable thead tr th {
    background-color:white !important;
}
.MyCandidateTable table table {
    background:white !important;
}
.videoUpload_input{
    display: none;
}
.MaterialReactTableStyle .css-131dqfq-MuiTableRow-root:hover td{
    background-color:#f0d6bb !important;
}
span.error_msg {
    color: #ef2850;
}

.loaderAdmin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0px !important;
    top: 0px !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 9999 !important;
}

@media (max-width: 960px) {
    .ButtonText button p{
        font-size: 11px !important;
    }
}
.accordianBorder.css-jd6zae-MuiPaper-root-MuiAccordion-root::before{
    position: absolute !important;
    left: 0 !important;
    top: -1px !important;
    right: 0 !important;
    height: 0px !important;
    content: "" !important;
    opacity: 1 !important;
}
.ProfileTable > div > div {
    max-height: 400px;
}


.tooltip123 {
    position: relative;
}
  
.tooltip123 .tooltiptext {
    width: 120px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 35px;
    margin-left: -60px;
}

.tooltip123 .tooltiptext123 {
    width: 120px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: -38px;
    margin-left: -60px;
}
 
.tooltip123 .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--button-bg-color-green) transparent transparent transparent;
}
.tooltip123 .tooltiptext123::after {
    content: "";
    position: absolute;
    bottom: 93%;
    rotate: 180deg;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--button-bg-color-green) transparent transparent transparent;
}
.tooltipSpanText{
    background-color: var(--button-bg-color-green);
    padding: 5px 20px;
    color: var(--button-bg-color);
    border-radius: 18px;
    font-size: 12px;
}
.tooltipSpanText123{
    color: var(--button-bg-color-green);
    border: 1px solid var(--button-bg-color-green);
    padding: 5px 20px;
    border-radius: 18px;
    font-size: 12px;
}


.pdfReport *{
    padding: 0;
    margin: 0;
}
.pdfReport {
    background-color: #f6f6f6;
}

.my-padding-30{
    padding:30px;
}
.my-padding-40-30{
    padding:40px 30px;
}
.margin-top-10{
    margin-top: 10px;
}
.margin-top-15{
    margin-top: 15px;
}
.margn-top-20{
    margin-top: 20px;
}
.margin-top-23{
    margin-top: 23px;
}
.margin-top-30{
    margin-top: 30px;;
}
.margin-top-40{
    margin-top: 40px;
}
.margin-top-70{
    margin-top:70px;
}

.my-margin-bottam{
    margin-bottom:14px;
}
.margn-bot-20{
    margin-bottom: 20px;
}
.margin-bottom-30{
    margin-bottom: 30px;
}
.margin-botom-40{
    margin-bottom: 40px;
}
.margin-27-27{
    margin: 25px 0px;
}
.anti-vdo-ans h4 {
    margin-bottom: 10px;
}
.wdt {
    /* max-width: 900px; */
    margin: 20px;
}
.my-bg-white{
    background: #fff;
}
.my-color{
    color:#46a997;
}

.my-d-flex{
    display: flex;
    /* flex-wrap: wrap; */
}
.my-align-center
{
    align-items: center;
}
.my-justify-center{
    justify-content: space-between;
}

/* .wdt p, .wdt a, .wdt span {
    font-size: 16px;
} */
.border-radius{
    border-radius: 10px;
}

.my-d-flex-n-jus {
    display: flex;
    justify-content: space-between;
}
.font-size{
    font-size: 16px;
}

.text-flexs-50{
    flex: 0 0 50%;
}
.text-padding-15-30 {
    padding:15px 30px;
}
.header-content{
    border-left: 3px solid #46a997;
}
.header-content h3:first-child{
    color:#46a997;
    margin-bottom: 6px;
}
.text-ctr{
    text-align: center;
}
.text-rit{
    text-align: right;
}
.fonts-28{
    font-size: 28px;
}

.status-div{
    border-top: 2px solid #f3f3f3;
    padding-top: 30px;
}

.james-heading h1 {
    font-size: 26px;
}
 h2 {
    font-size: 22px;
}
.anti-section h2 {
    margin-bottom: 14px;
}
.james-heading div:last-child {
    text-align: right;
}
/* .anti-section {
    margin-top: 10px;
} */
.james-heading div img {
    width: 22px;
}

.pdf-section {
    background: #f6f6f6;
}
.pdf-section p{
    line-height: 1.4;
}

.anti-section a{
    text-decoration: none;
}

.anti-section a {
    font-size: 17px;
    font-weight: 600;
}

.anti-section a img {
    margin-right: 10px;
    width: 14px;
}
.James-section ul {
    margin-top: 30px;
}
.James-section ul li{
    line-height: 2.1;
}
 li.my-d-flex-n-jus strong img {
    margin-right: 20px;
}
 strong.strong-margn-rit{
    margin-right: 6px;
}

 ul.top-scor li {
    display:flex;
    position: relative;
    /* margin-bottom:24px; */
    align-items: center;
}

 ul.top-scor li:before {
    content: '';
    position: absolute;
    top: calc(50% - 3px);
    left: 0px;
    background: #000;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

.logo-img img{
    margin-right: 10px;
}

 ul.top-scor li:last-child:before{
    display: none;
}

.text-progress-br-gr{
    background: #46a997;
    color: #fff;
    border-radius: 6px 0px 0px 6px;
}

.text-progress-br-rd{
    background: #ca7b79;
    color: #fff;
}

.text-progress-br-gry {
    background: #dfdfdf;
    color: #000;
    border-radius: 0px 6px 6px 0px;
}

.text-progress-br-same{
    font-size: 20px;
    font-weight: 600;
    padding: 2px;
}
 ul.top-scor .top-scor-jus-pad > p {
    padding-left: 20px;
}
 ul.top-scor li p {
    margin-right: 20px;
}

.top-scor-li p:before {
    display: inline-block;
    content: '';
    width: 10px;
    height: 10px;
    background: #46a997;
    border-radius: 50%;
    margin-right: 14px
}
 P.rd-dt:before{
    background: #ca7b79;
}
 P.gry-dt:before{
    background:#dfdfdf;
}
.clock-img img{
    margin-right: 5px;
}

.description-padding{
    margin: 36px 0px 8px 0px;
}
.description-div p {
    line-height: 23px;
}

.description-div ul {
    margin-left: 30px;
    margin-top: 8px;
}
.description-div ul li {
    line-height: 23px;
}
.list-at{
    list-style:auto;
}
.cusrm-pading {
    padding: 16px;
    margin-top: 5% !important;
}
.cusrm-margin{
    margin-top: 20px;
}
.cusrm-pading h3 img {
    margin-right: 12px;
}

 p.cusm-parograph img{
    width: 18px;
}

.cusrm-rev-parograph {
    margin-bottom: 15px;
}
.cusrm-rev-parograph p {
    text-transform: uppercase;
    color: #9a9a9a;
    font-weight: 600;
}
.custm-stat-div p {
    max-width:650px;
}
.custm-stat-div  div img{
    width: 18px;
}

.castom-focus-heading ul {
    margin-top: 0px;
}
.castom-focus-heading ul li {
    list-style: inside;
    margin: 10px 0px 12px 0px;
    font-weight: 600;
    font-size: 21px;
}
.castm-ans-margn-top {
    margin-top: 28px;
}

.retig-stats-div{
    border-top: 2px solid #f3f3f3;
    padding-top: 16px;
}
.description-div {
    margin-bottom: 23px;
}
.description-div.anti-vdo-ans ul.list-at {
    margin: 0px 0px 16px 20px;
}
 ul.top-scor li > div:nth-child(2) > div > span:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.retig-stats-div > div + div {
    min-width: 110px;
    margin-left: 10px;
}

@media(max-width:534px){
    .res-margn-top{
        margin-top: 10px;
    }
     ul.top-scor li {
        display:unset; 
    }
     ul.top-scor li:before {
        top: calc(50% - 20px);
    }
    .my-d-flex.text-ctr {
        margin: 10px 0px;
    }
    .anti-section.clock-img {
        margin-top: 10px;
    }
    .text-flexs-50 {
        flex: 0 0 100%;
    }
    .text-flexs-50.header-content.text-padding-15-30 {
        margin-top: 10px;
    }
    .text-flexs-50.my-d-flex {
        margin: 16px 0px;
    }
    .my-d-flex-n-jus.status-div div:nth-child(2) {
        margin: 0px 10px;
    }
     ul.res-menu li strong {
        min-width: 90px;
        margin-left: 20px;
    }
    .anti-section a {
        font-size: 14px;
    }
}

@media(max-width:534px){
     strong.strong-margn-rit {
        margin-right: 0px;
    }
}
.react-split__pane{
    height: 100% !important;
}
.split-sash-content {
    width: 1px !important;
    background-color: #E0E0E0;
    cursor: col-resize;
}

.DateCalandera .MuiOutlinedInput-notchedOutline {
    border-color: var(--border-color-Green) !important;
}

.css-bcz8li-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected {
    background-color:var(--button-bg-color-green) !important;
}
.css-1iiqanv-MuiButtonBase-root-MuiPickersDay-root.Mui-selected{
    background-color:var(--button-bg-color-green) !important;
}
ul[aria-labelledby="MenuItemContoury"] {
    max-height: 200px;
    }
    
    
    
    ul[aria-labelledby="MenuItemState"] {
    max-height: 200px;
    }
    
    
    
    ul[aria-labelledby="MenuItemRaised"] {
    max-height: 200px;
    }



    .testcasetab button .Mui-selected{
        color: red !important;
        font-size: 16px !important;
        font-weight: 700 !important;
      }
    
      .testcasetab button { 
        text-transform: capitalize;
      } 
      .checkBoxBorder .MuiFormControlLabel-root  .Mui-checked span {
        border-width: 2px;
        border-style: solid;
    }
    .RemoveOutLine :focus-visible{
        outline:none;
    }