/* *********** */

.invite_popup_modal__backdrop {
	background: rgba(0, 0, 0, .65);
	bottom: 0;
	left: 0; 
	position: fixed;
	right: 0;
	top: 0;
	z-index: 999;
}

.invite_popup_modal__container {
	background: #fff;
	border-radius: 5px;
	max-width: 100%;
	margin: 50px auto;
	padding: 15px;
	width: 1100px;
}
 


	.MuiTableBody-root1 table tbody > tr:hover {
		background-color:#f0d6bb !important;
	  }
	/* .MuiTableBody-root12 table tbody > tr:hover {
		background-color:#f0d6bb !important;
	  }
	table tr {
		background-color: white !important;
	} */
	.css-1enotpu-MuiTableRow-root:nth-of-type(odd){
		background-color: white !important;
	}
	.css-1enotpu-MuiTableRow-root:nth-of-type(odd):hover{
		background-color: #f0d6bb !important;
		cursor: grab;
	}
	.css-1enotpu-MuiTableRow-root:nth-of-type(even):hover{
		background-color: #f0d6bb !important;
		cursor: grab;
	}

	.ql-toolbar.ql-snow + .ql-container.ql-snow {
		overflow: scroll;
	}